<template>
  <div id="login-container">
    <div class="login-card">
      <div class="form-container">
        <div class="header">بازیابی رمز عبور</div>
        <img class="liner" src="../../assets/images/Line-login.svg" alt="" />
        <form class="login-form" @submit.prevent>
          <label
            class="input-label"
            for=""
            :class="[
              this.$store.state.status === 'error' ? 'error' : '',
              this.message.mobile ? 'error' : ''
            ]"
          >
            <img src="../../assets/images/mdi_account-circle-outline.svg" />
            <input
              v-model="mobile"
              type="text"
              name=""
              id=""
              placeholder="شماره موبایل"
              @input="acceptNumber"
            />
          </label>
          <div
            v-if="this.$store.state.status === 'error' || this.message.mobile"
            class="error-massage"
          >
            {{ this.$store.state.message || this.message.mobile }}
          </div>
          <button @click="getCode" class="btn-singup">دریافت کد</button>
          <button @click="login" class="btn-register">ورود</button>
        </form>
      </div>
      <img
        class="login-image"
        src="../../assets/images/auth-image.svg"
        height="502"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mobile: "",
      originalMobile: "",
      message: {
        mobile: ""
      }
    };
  },
  methods: {
    getCode() {
      this.message = {};
      if (this.validateMobile()) {
        let data = {
          mobile: this.originalMobile
        };
        this.$store
          .dispatch("forgetPasswordMobilVerification", data)
          .then(() => {
            this.$router.push("/forget-password-code-verify");
          })
          .catch(error => {
            console.log(error.response);
          });
      }
      if (this.originalMobile.length < 11) {
        this.message.mobile = "فرمت شماره موبایل اشتباه است";
      }

      if (!this.originalMobile) {
        this.message.mobile = "شماره موبایل را وارد کنید";
      }
      // this.$router.push("/forget-password-code-verify");
    },
    login() {
      this.$router.push("/login");
    },
    acceptNumber() {
      let x = this.mobile
        .replace(/\D/g, "")
        .match(/(\d{0,4})(\d{0,3})(\d{0,4})/);
      this.mobile = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    validateMobile() {
      return this.originalMobile.length >= 11;
    }
  },
  watch: {
    mobile() {
      this.originalMobile = this.mobile.replace(/\(|\)|\s|-/g, "");
    }
  }
};
</script>
<style scoped>
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(248, 241, 241);
  opacity: 1;
}

#login-container {
  width: 100%;
  height: 100vh;
  background: linear-gradient(75.38deg, #5c6699 0%, #070d59 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  /*padding: 0 70px;*/
}

.error {
  border: 1px solid #ff6b6b;
}

.error-massage {
  margin-top: -9px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #ff6b6b;
  text-align: right;
  cursor: pointer;
  align-self: flex-start;
}

.login-card {
  background: #1f3c88;
  border-radius: 20px;
  width: 1052px;
  height: 545px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0px 160px;
}

.login-image {
  position: absolute;
  left: -14%;
  top: -18%;
  width: 70%;
}

.form-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 6%;
  width: 88%;
  max-width: 400px;
}

.header {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 44px;
  color: #fff;
  margin-bottom: 10px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-label {
  display: flex;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  width: 350px;
  height: 30px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 12px;
}

.input-label input {
  background-color: transparent;
  border: none;
  height: 100%;
  outline: none;
  width: calc(100% - 35px);
  padding-right: 10px;
  color: #ffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  direction: initial;
  text-align: right;
}

.input-label img {
  height: 20px;
  padding-right: 10px;
}

.input-password {
  background: #ffffff;
  opacity: 0.2;
  border-radius: 5px;
  outline-style: none;
  border: none;
  outline-style: none;
  width: 350px;
  height: 30px;
  /* margin-bottom: 42px; */
}

.liner {
  margin-bottom: 6px;
}

.inptu-icon {
  margin: 0;
  padding: 0;
  position: relative;
  top: -27px;
  right: -155px;
  opacity: 75%;
  color: #fff;
}

.input-label-text {
  margin: 0;
  padding: 0;
  position: relative;
  top: -62px;
  right: -93px;
  opacity: 75%;
  color: #fff;
}

.forget-password {
  position: relative;
  margin-top: -15px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: #fff;
  opacity: 0.6;
  text-align: right;
  text-decoration-line: underline;
  cursor: pointer;
  align-self: flex-start;
}

.btn-singup {
  font-family: "Vazir Medium FD";
  border: none;
  background: #ffffff;
  border-radius: 10px;
  width: 285px;
  height: 40px;
  margin-top: 28px;
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  color: #070d59;
  cursor: pointer;
}

.btn-register {
  font-family: "Vazir Medium FD";
  margin-top: 5px;
  margin-bottom: 5px;
  border: none;
  width: 285px;
  height: 40px;
  background: #1f3c88;
  border: 1px solid #ffffff;
  border-radius: 10px;
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  cursor: pointer;
}

.text-information {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #fff;
  position: relative;
  right: -34px;
  cursor: pointer;
}

/* responseiv section */
@media only screen and (max-width: 320px) {
  .form-container {
    min-width: 100%;
    margin: 0 auto;
  }

  .login-card {
    width: 100%;
    margin: auto;
  }

  .form-container {
    min-width: 100%;
    margin: 0 auto;
  }

  .login-image {
    display: none;
  }

  .header {
    font-size: 1.5em;
  }

  .liner {
    width: 100%;
  }

  .input-label {
    width: 100%;
  }

  .input-label-error {
    width: 80%;
  }

  .input-label input {
    font-size: 0.7em;
  }

  .input-label-error input {
    font-size: 0.7em;
  }

  .input-label img {
    width: 30px;
  }

  .btn-singup {
    width: 100%;
    font-size: 16px;
  }

  .btn-register {
    width: 100%;
    font-size: 16px;
  }

  .text-information {
    margin-right: 60px;
    font-size: 0.5em;
  }

  .error-massage {
    font-size: 0.5em;
  }

  .forget-password {
    font-size: 0.5em;
  }
}

@media (min-width: 321px) and (max-width: 480px) {
  #login-container {
  }

  .form-container {
    margin: 0 auto;
  }

  .login-card {
    width: 100%;
  }

  .text-information {
    margin-right: 60px;
    font-size: 0.5em;
  }

  .header {
    font-size: 1.5em;
  }

  .input-label {
  }

  .input-label input {
    font-size: 0.7em;
  }

  .input-label-error {
  }

  .input-label-error input {
    font-size: 0.7em;
  }

  .input-label img {
    width: 30px;
  }

  .error-massage {
    font-size: 0.5em;
  }

  .forget-password {
    font-size: 0.5em;
  }

  .liner {
    width: 100%;
  }

  .btn-singup {
    width: 80%;
    font-size: 1em;
  }

  .btn-register {
    width: 80%;
    font-size: 1em;
  }
}

@media only screen and (max-width: 1140px) {
  .login-image {
    left: -13%;
    top: -13%;
    width: 65%;
  }
}

@media only screen and (max-width: 960px) {
  .login-image {
    left: -11%;
    width: 60%;
    top: -8%;
  }

  #login-container {
    padding: 0 10px;
  }

  .login-card {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 768px) {
  .login-image {
    display: none;
  }
}
</style>
